<template>
  <div class="flex items-center">
    <vs-avatar :src="this.author.photoURL" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ this.author.name }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererPartner',
  data() {
    return {
      author: {
        name: 'loading...',
        photoURL: 'https://portal-cdn.rapid.education/partner-pictures/default.jpeg'
      }
    }
  },
  computed: {
    url () {
      return `/a/partners/${this.params.value}`
    },
    authorId() {
      return this.params.data.author;
    },
  },
  created() {

    if (!this.params.value) {
      this.author.name = 'Rapid Education';
      return;
    }

    this.$http.get(`partners/${this.params.value}`)
      .then(response => {

        if (response.data.data) {
          this.author = response.data.data;
          const pictureId = this.author.logo_url || 'default';
          this.author.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
        }

      })
      .catch(exception => {

        let error = 'An unknown error occurred loading partner details';
        if (exception.response) {
          error = exception.response.data.error.description;
        }

        return this.$vs.notify({
          title: 'Failed to partner',
          text: error,
          color: 'danger',
          position: 'top-right',
        });
      });

  }
};
</script>
