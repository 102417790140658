<template>
  <div class="flex items-center">
    <span v-if="this.params.data.type === 'service'">
      {{ this.countPrices }} price{{ this.countPrices === 1 ? '' : 's' }}
    </span>
    <span v-else>
      {{ this.countChildren }} {{ this.countChildren === 1 ? 'child' : 'children' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    countPrices() {
      return (this.params.data || {price_ids: []}).price_ids.length;
    },
    countChildren() {
      return (this.params.data || {children_ids: []}).children_ids.length;
    },
  },
};
</script>
